import Api from "./Api";
import JwtService from "@/core/services/jwt.service";

export const token = JwtService.getToken();

export default {
  fetchUsers(page) {
    return Api().get("api/user", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: page,
      },
    });
  },
  fetchUser(id) {
    return Api().get(`api/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  addToFolder(folderId, list) {
    let fd = new FormData();

    list.forEach((e) => {
      fd.append("qrcodes[]", String(e));
    });

    return Api().post(`api/folder/${folderId}/sync`, fd, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  addToQrcodeFolders(folderId, qrId) {
    let fd = new FormData();
    fd.append("folders[]", String(folderId));

    return Api().post(`api/qrcode/${qrId}/folders`, fd, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};
