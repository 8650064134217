import Vue from "vue";
import Router from "vue-router";
import store from "@/core/services/store";
import JwtService from "@/core/services/jwt.service";
import CookieService from "@/core/services/cookie.service";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/createqr",
      component: () => import("@/view/layout/Layout"),
      meta: { requiresAuth: true },
      children: [
        // {
        //   path: "/dashboard",
        //   name: "dashboard",
        //   meta: { requiresAuth: true },
        //   component: () => import("@/view/pages/Dashboard.vue"),
        // },
        {
          path: "/about",
          name: "about",
          component: () => import("@/view/pages/About.vue"),
        },
        {
          path: "/news",
          name: "news",
          component: () => import("@/view/pages/News.vue"),
        },
        {
          path: "/profile/:tab",
          name: "profile",
          meta: { requiresAuth: true },
          component: () => import("@/view/pages/profile/Profile.vue"),
        },
        // 🚀  __USER START__ 🚀  \\
        {
          path: "/subscriptions",
          name: "userSubscriptions",
          component: () => import("@/view/pages/user/Subscription2.vue"),
        },
        {
          path: "/user/orders",
          name: "userOrders",
          component: () => import("@/view/pages/user/Orders.vue"),
        },
        {
          path: "/user/package-history",
          name: "package-history",
          component: () => import("@/view/pages/user/PackageHistory.vue"),
        },
        // {
        //   path: "/mycart",
        //   name: "mycart",
        //   component: () => import("@/view/pages/Payment-Cart.vue"),
        // },
        // 🚀  __USER END__ 🚀  \\
        /**
         * @QrCodes :start
         */
        {
          path: "/createqr",
          name: "qrcodelist",
          meta: { requiresAuth: true, requiresVersionControl: true },
          component: () => import("@/view/pages/QrCodeList"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
        },
        {
          path: "/(createqr|updateqr)/socialmedia/:data?",
          name: "socialmedia",
          component: () => import("@/view/pages/qrcodes/SocialMedia.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/staffcard/:data?",
          name: "staffcard",
          component: () => import("@/view/pages/qrcodes/StaffCard.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/business/:data?",
          name: "business",
          redirect: "/(createqr|updateqr)/24",
          //   component: () => import("@/view/pages/qrcodes/Business.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/restaurant/:data?",
          name: "restaurant",
          component: () => import("@/view/pages/qrcodes/Restaurant.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/catalog/:data?",
          name: "catalog",
          component: () => import("@/view/pages/qrcodes/Catalog.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/vcard",
          name: "vcard",
          redirect: "/(createqr|updateqr)/1",
          //   component: () => import("@/view/pages/qrcodes/VCard.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          // props: (route) => ({ qrcodeId: parseInt(route.query.id), temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/vcardstatic/:data?",
          name: "vcardstatic",
          component: () => import("@/view/pages/qrcodes/VcardStatic.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { checkRemainingStatic: true },
        },
        {
          path: "/(createqr|updateqr)/textstatic/:data?",
          name: "textstatic",
          component: () => import("@/view/pages/qrcodes/TextStatic.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { checkRemainingStatic: true },
        },
        {
          path: "/(createqr|updateqr)/emergency/:data?",
          name: "emergency",
          component: () => import("@/view/pages/qrcodes/Emergency.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/equipment/:data?",
          name: "equipment",
          component: () => import("@/view/pages/qrcodes/Equipment.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/event/:data?",
          name: "event",
          component: () => import("@/view/pages/qrcodes/Event.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        // {
        //   path: "/(createqr|updateqr)/facebook/:data?",
        //   name: "facebook",
        //   component: () => import("@/view/pages/qrcodes/PopularPlatforms.vue"),
        //   props: route => ({ id: route.query.id, temp: route.query.temp })
        // },
        // {
        //   path: "/(createqr|updateqr)/youtube/:data?",
        //   name: "youtube",
        //   component: () => import("@/view/pages/qrcodes/Youtube.vue"),
        //   props: route => ({ id: route.query.id, temp: route.query.temp })
        // },
        // {
        //   path: "/(createqr|updateqr)/linkedin/:data?",
        //   name: "linkedin",
        //   component: () => import("@/view/pages/qrcodes/Linkedin.vue"),
        //   props: route => ({ id: route.query.id, temp: route.query.temp })
        // },
        {
          path: "/(createqr|updateqr)/pet/:data?",
          name: "pet",
          component: () => import("@/view/pages/qrcodes/Pet.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/location/:data?",
          name: "location",
          component: () => import("@/view/pages/qrcodes/Location.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        // {
        //   path: "/(createqr|updateqr)/call/:data?",
        //   name: "call",
        //   component: () => import("@/view/pages/qrcodes/Call.vue"),
        //   props: route => ({ id: route.query.id, temp: route.query.temp })
        // },
        // {
        //   path: "/(createqr|updateqr)/store/:data?",
        //   name: "store",
        //   component: () => import("@/view/pages/qrcodes/Store.vue"),
        //   props: route => ({ id: route.query.id, temp: route.query.temp })
        // },
        {
          path: "/(createqr|updateqr)/paypal/:data?",
          name: "paypal",
          component: () => import("@/view/pages/qrcodes/Paypal.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/pdf/:data?",
          name: "pdf",
          component: () => import("@/view/pages/qrcodes/Pdf.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/pdfmenu/:data?",
          name: "pdfmenu",
          component: () => import("@/view/pages/qrcodes/PdfMenu.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/website/:data?",
          name: "website",
          component: () => import("@/view/pages/qrcodes/Website.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/websitestatic/:data?",
          name: "websitestatic",
          component: () => import("@/view/pages/qrcodes/WebsiteStatic.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { checkRemainingStatic: true },
        },
        {
          path: "/(createqr|updateqr)/wifistatic/:data?",
          name: "wifi",
          component: () => import("@/view/pages/qrcodes/Wifi.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { checkRemainingStatic: true },
        },
        {
          path: "/(createqr|updateqr)/smsstatic/:data?",
          name: "smsstatic",
          component: () => import("@/view/pages/qrcodes/Smsstatic.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { checkRemainingStatic: true },
        },
        // {
        //   path: "/(createqr|updateqr)/text/:data?",
        //   name: "text",
        //   component: () => import("@/view/pages/qrcodes/Text.vue"),
        //   props: route => ({ id: route.query.id, temp: route.query.temp })
        // },
        {
          path: "/(createqr|updateqr)/phonestatic/:data?",
          name: "phonestatic",
          component: () => import("@/view/pages/qrcodes/PhoneStatic.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { checkRemainingStatic: true },
        },
        {
          path: "/(createqr|updateqr)/museum/:data?",
          name: "museum",
          component: () => import("@/view/pages/qrcodes/Museum.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/rate/:data?",
          name: "rate",
          component: () => import("@/view/pages/qrcodes/Rate.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/coupon/:data?",
          name: "coupon",
          component: () => import("@/view/pages/qrcodes/Coupon.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/music/:data?",
          name: "music",
          component: () => import("@/view/pages/qrcodes/Music.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/:platform(smsdynamic|wifidynamic|pinterest|twitter|instagram|store|linkedin|facebook|youtube|whatsapp|call|text|mail|store)/:data?",
          name: "popularplatforms",
          component: () => import("@/view/pages/qrcodes/PopularPlatforms.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/multiplelink/:data?",
          name: "multiplelink",
          component: () => import("@/view/pages/qrcodes/MultipleLink.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/gallery/:data?",
          name: "gallery",
          component: () => import("@/view/pages/qrcodes/Gallery.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/customerservice/:data?",
          name: "customerservice",
          component: () => import("@/view/pages/qrcodes/CustomerService.vue"),
          props: (route) => ({ id: route.query.id, temp: route.query.temp }),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/(createqr|updateqr)/:id",
          name: "qrcode_index",
          component: () => import("@/view/pages/qrcodes/index.vue"),
          props: (route) => ({ qrcodeId: parseInt(route.query.id), temp: route.query.temp }),
          meta: { isSubscriptionRequired: true, unsavedChangesController: true },
        },
        {
          path: "/statistics/:type/:id",
          name: "statistics",
          component: () => import("@/view/pages/QrcodeStatistic.vue"),
          props: true,
          meta: { isSubscriptionRequired: true },
        },
        /**
         * @QrCodes :end
         */

        {
          path: "/mymedia",
          name: "myMedia",
          component: () => import("@/view/pages/folders/MyMedia.vue"),
          meta: { isSubscriptionRequired: true },
        },
        {
          path: "/qrlist/:type?",
          name: "qrlist",
          component: () => import("@/view/pages/folders/SubFolders.vue"),
          props: (route) => ({
            page: route.query.page,
            type: route.query.type,
            status: route.query.status,
            parent_id: route.query.parent_id,
            redirectProp: !!route.query.redirectProp ? JSON.parse(window.atob(route.query.redirectProp)) : null,
          }),
        },
        {
          path: "/rate/:id/comments",
          name: "comments",
          component: () => import("@/view/pages/Comments.vue"),
        },
        {
          path: "/userfolders/:id",
          name: "userFolders",
          component: () => import("@/view/pages/folders/UserFolders.vue"),
          props: (route) => ({
            page: route.query.page,
            folderName: route.query.n,
          }),
        },
        {
          path: "/customize/:type/:id",
          name: "customizer",
          props: (route) => ({
            redirectProp: !!route.query.redirectProp ? JSON.parse(window.atob(route.query.redirectProp)) : null,
          }),
          component: () => import("@/view/pages/CustomizeQRV2.vue"),
        },
        {
          path: "/customizebanner/:type/:id",
          name: "banner-customizer",
          component: () => import("@/view/pages/CustomizeQR_Banner.vue"),
        },

        // 🚀  __PRODUCTS START__ 🚀  \\
        {
          path: "/products/:product?",
          name: "products",
          component: () => import("@/view/pages/Products.vue"),
        },
        // {
        //   path: "/ideas",
        //   name: "ideas",
        //   component: () => import("@/view/pages/Ideas.vue"),
        // },
        {
          path: "/ticket",
          name: "ticket",
          component: () => import("@/view/pages/Ticket.vue"),
        },
        {
          path: "/feedback",
          name: "feedback",
          component: () => import("@/view/pages/Feedback.vue"),
        },
        /** start
         * @HELP
         */
        {
          path: "/help",
          name: "help",
          component: () => import("@/view/pages/Help.vue"),
        },
        {
          path: "/help/faq",
          name: "faq",
          component: () => import("@/view/pages/help/FAQ.vue"),
        },
        {
          path: "/help/whyQRCode",
          name: "whyQRCode",
          component: () => import("@/view/pages/help/whyQRCode.vue"),
        },
        {
          path: "/help/billing",
          name: "billing",
          component: () => import("@/view/pages/help/billing.vue"),
        },
        {
          path: "/help/analytic",
          name: "analytic",
          component: () => import("@/view/pages/help/analytic.vue"),
        },
        /** end
         * @HELP
         */
        {
          path: "/payment/:result(fail|success|awaiting)",
          name: "fail",
          component: () => import("@/view/pages/PaymentResult.vue"),
          meta: { isCookieRequired: true, cookieName: "payment" },
        },
        {
          path: "/test",
          name: "testingGround",
          component: () => import("@/view/pages/TestingGround.vue"),
        },
      ],
    },
    {
      path: "/welcome",
      name: "welcome",
      meta: { isIdRequired: true },
      component: () => import("@/view/pages/LandingPage.vue"),
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      props: (route) => ({
        code: route.query.code,
      }),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
      ],
    },
    {
      name: "reset-password",
      path: "/reset-password/:token",
      component: () => import("@/view/pages/auth/ResetPassword.vue"),
    },
    {
      name: "email-verify",
      path: "/email/verify/:id/:token",
      component: () => import("@/view/pages/auth/VerifyEmail.vue"),
    },
    // {
    //   path: "/payment/success",
    //   name: "success",
    //   component: () => import("@/view/pages/PaymentSuccess.vue"),
    // },
    // {
    //   path: "/payment/fail",
    //   name: "fail",
    //   component: () => import("@/view/pages/PaymentFail.vue"),
    // },
    {
      path: "/version",
      name: "versioncontrolpage",
      component: () => import("@/view/pages/VersionControlPage.vue"),
    },
    {
      path: "/terms-and-conditions",
      name: "terms-and-conditions",
      component: () => import("@/view/pages/TermsAndConditions.vue"),
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});

router.beforeLeave = (to, from, next) => {
  console.log("🚀 ~ file: router.js:232 ~ router.beforeLeave= ~ to", to);
};

router.beforeEach(async (to, from, next) => {
  // if (/^\/(createqr|updateqr)\/\w+/gm.test(to.path)) {
  //   store.dispatch("util/fetchVideoTutorial", to.path.split("/")[2]);
  // }
  if (to.meta.requiresAuth) {
    if (!store.state.auth.isAuthenticated) {
      next({
        name: "login",
      });
    }
  }
  // if (to.meta.requiresVersionControl) {
  //   versionController(to, from, next);
  // }
  // next();

  if (to.meta.isCookieRequired) {
    const isExist = CookieService.getCookieValue(to.meta.cookieName);
    console.log("🚀 ~ file: router.js:525 ~ router.beforeEach ~ isExist :", isExist);
    if (!isExist)
      next({
        path: "/",
      });
    else next();
  }

  if (to.meta.isLoggedIn) {
    if (store.state.auth.isAuthenticated) {
      next({
        name: "qrcodelist",
      });
    } else next();
  }
  if (to.meta.isSubscriptionRequired) {
    if (store.state.auth.user?.package?.content) next();
    else
      next({
        path: "/",
      });
  }
  if (to.meta.checkRemainingStatic) {
    const hasActivePackage = store.state.auth.user?.package?.content;
    const hasStaticLeft =
      store.state.qrcode.packageDetails?.package?.static - store.state.qrcode.packageDetails?.static;
    if (hasActivePackage) next();
    else if (!!hasStaticLeft) next();
    else
      next({
        path: "/",
      });
  }
  if (to.meta.isIdRequired) {
    if (!to.query.id)
      next({
        name: "login",
      });
    else next();
  }
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  next();
});

router.afterEach((to, from) => {
  sendMetricaEvent(to.fullPath);
});

// function versionController(to, from, next) {
//   const userAgent = window.navigator.userAgent;
//   let version =
//     userAgent.includes("Chrome") &&
//     userAgent
//       .split(" ")
//       .find((e) => e.includes("Chrome"))
//       .split("/")[1];
//   version = parseInt(version);

//   if (version < 112 && !window.isVersionChecked) next({ name: "versioncontrolpage" });
//   else next();
// }

function sendMetricaEvent(route) {
  if (!window.ym || !window.gtag) return;

  const eventData = {
    route: route,
  };
  window.ym(86330776, "reachGoal", "ROUTE", eventData);
  window.gtag("event", "ROUTE", eventData);
}

export default router;
