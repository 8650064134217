// import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import AuthenticationService from "@/core/services/AuthenticationService";
import ErrHandler from "@/core/services/errorHandle.service";
import { SEND_SNACKBAR } from "./snackbar.module";
import i18n from "@/core/plugins/vue-i18n";
import router from "../../../router";

// action types
export const VERIFY_AUTH = "auth/verifyAuth";
export const LOGIN = "auth/login";
export const LOGIN_OAUTH = "auth/loginOAuth";
export const AUTO_LOGIN = "auth/autoLogin";
export const FETCH_USER = "auth/fetchUser";
export const LOGOUT = "auth/logout";
export const REGISTER = "auth/register";
export const UPDATE_PASSWORD = "auth/updateUser";
export const RESET_PASSWORD = "auth/resetPassword";
export const PACKAGE_CONTROLLER = "auth/packageController";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_AUTO_LOGIN_STATE = "auth/setAutoLoginState";
export const SET_ERROR = "setError";
// export const SET_SNACKBAR = "setSnackbar";
export const REQUEST_RESET_PASSWORD = "auth/requestResetPassword";
export const SET_LEAVE_CONFIRMED = "auth/setLeaveConfirmed";
export const SET_LEAVE_DIALOG = "auth/setLeaveDialog";
export const SET_ROUTE_TO = "auth/setRouteTo";

const state = {
  errors: null,
  user: {
    info: {
      avatar: null,
    },
  },
  isAuthenticated: false,
  isPasswordReset: false,
  leaveConfirmed: true,
  leaveDialog: false,
  routeTo: null,
  snackbar: [],
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  currentLeaveDialog(state) {
    return state.leaveDialog;
  },
  currentLeaveConfirmed(state) {
    return state.leaveConfirmed;
  },
  snackbarValue(state) {
    return state.snackbar.value;
  },
  currentRouteTo(state) {
    return state.routeTo;
  },
};

const actions = {
  async [LOGIN](context, credentials) {
    try {
      context.commit("qrcode/purge");
      context.commit("util/purge");
      context.commit("snackbar/purge");
      context.commit(PURGE_AUTH);
      delete sessionStorage.vuex;
      localStorage.removeItem("credentials");
      const response = await AuthenticationService.login(credentials);

      localStorage.setItem("credentials", JSON.stringify(credentials));
      if (!response.data.data.email_verified) {
        context.dispatch(
          `${SEND_SNACKBAR}`,
          {
            color: "red",
            message: i18n.t("SNACKBAR.verify_email"),
          },
          { root: true }
        );
      } else {
        JwtService.saveToken(response.data.data.token);
        // JwtService.saveUser(JSON.stringify(response.data.data));
        context.commit(SET_AUTH, response.data.data);
        context.dispatch(
          `${SEND_SNACKBAR}`,
          {
            color: "green",
            message: i18n.t("SNACKBAR.login_successful"),
          },
          { root: true }
        );
      }
      return true;
    } catch (err) {
      if (err.response?.data?.code) return err.response.data;
      ErrHandler(err);
      return err.response?.data;
    }
  },
  async [LOGIN_OAUTH](context, hashCode) {
    try {
      context.commit("qrcode/purge");
      context.commit("util/purge");
      context.commit("snackbar/purge");
      context.commit(PURGE_AUTH);
      delete sessionStorage.vuex;
      localStorage.removeItem("credentials");
      const response = await AuthenticationService.loginOAuth(hashCode);
      const user = response.data.data;

      JwtService.saveToken(user.token);
      context.commit(SET_AUTH, user);
      context.dispatch("qrcode/fetchUserQrTypes", { root: true });
      return true;
    } catch (err) {
      if (err.response?.data?.code) return err.response.data;
      ErrHandler(err);
    }
  },
  async [AUTO_LOGIN](context) {
    try {
      context.commit("qrcode/purge");
      context.commit("util/purge");
      context.commit("snackbar/purge");
      context.commit(PURGE_AUTH);
      delete sessionStorage.vuex;
      localStorage.removeItem("credentials");
      const response = await AuthenticationService.login(JSON.parse(localStorage.getItem("credentials")));

      JwtService.saveToken(response.data.data.token);
      context.commit(SET_AUTH, response.data.data);
      context.dispatch("qrcode/fetchUserQrTypes", { root: true });
      return context.user;
    } catch (error) {
      localStorage.removeItem("credentials");
      return null;
    }
  },
  async [FETCH_USER](context, payload) {
    try {
      let id;
      if (!payload?.id) id = context.state.user.id;
      const response = await AuthenticationService.fetchUserById(id);
      context.commit(SET_AUTH, response.data.data);

      return response;
    } catch (error) {
      ErrHandler(error);
    }
  },
  async [LOGOUT](context) {
    try {
      await AuthenticationService.logout();
    } catch (error) {
      console.error(error.response ? error.response : error);
    }
    context.commit("qrcode/purge");
    context.commit("util/purge");
    context.commit("snackbar/purge");
    context.commit(PURGE_AUTH);
    delete sessionStorage.vuex;
    localStorage.removeItem("credentials");

    if (!state.isAuthenticated) {
      context.dispatch(
        `${SEND_SNACKBAR}`,
        {
          color: "yellow",
          message: i18n.t("SNACKBAR.logged_out"),
        },
        { root: true }
      );
    }
  },
  async [REGISTER](context, credentials) {
    try {
      const response = await AuthenticationService.register(credentials);

      context.dispatch(
        `${SEND_SNACKBAR}`,
        {
          color: "green",
          message: i18n.t("SNACKBAR.registeration_successful"),
        },
        { root: true }
      );
      JwtService.saveToken(response.data.token);
      // JwtService.saveAccessToken(response.data.token);
      return response.data;
    } catch (err) {
      context.dispatch(
        `${SEND_SNACKBAR}`,
        {
          color: "red",
          message: err.response?.data?.message,
        },
        { root: true }
      );
    }
  },
  async [RESET_PASSWORD](context, payload) {
    try {
      const response = await AuthenticationService.forgotPassword(payload);
      context.commit(REQUEST_RESET_PASSWORD);
      return true;
    } catch (err) {
      ErrHandler(err);
      return false;
    }
  },
  [PACKAGE_CONTROLLER](ctx, payload) {
    const is = !!ctx.state.user.package?.content;
    if (is) return true;

    const errorContent = payload ?? "packageController";

    const alert = {};
    alert.vHtml = i18n.t(`POPUP.${errorContent}`);
    alert.isVCard = false;
    alert.title = "";
    alert.callback = () => {
      router.push({ path: "/subscriptions" });
    };
    alert.isPersistant = false;
    ctx.dispatch("util/alert", alert, { root: true });
    return false;
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    if (!!user.avatar) {
      state.user.info.avatar = user.avatar;
      return;
    }
    state.isAuthenticated = true;
    state.user = user;

    if (!!!user.info.avatar)
      state.user.info.avatar = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";
    state.errors = {};
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [SET_AUTO_LOGIN_STATE](state) {
    state.token = JwtService.getToken();
    state.isAuthenticated = true;
  },
  async [PURGE_AUTH](state) {
    const promise = new Promise((resolve) => {
      const defaultState = {
        errors: null,
        user: {
          info: {
            avatar: null,
          },
        },
        isAuthenticated: false,
        isPasswordReset: false,
        leaveConfirmed: true,
        leaveDialog: false,
        routeTo: null,
        snackbar: [],
      };

      Object.keys(defaultState).forEach((key) => {
        state[key] = defaultState[key];
      });

      // sessionStorage.clear();
      // localStorage.clear();
      JwtService.destroyToken();

      resolve();
    });
  },
  [REQUEST_RESET_PASSWORD](state) {
    state.isPasswordReset = true;
  },
  [SET_LEAVE_CONFIRMED](state, payload) {
    state.leaveConfirmed = payload;
  },
  [SET_LEAVE_DIALOG](state, payload) {
    state.leaveDialog = payload;
  },
  [SET_ROUTE_TO](state, payload) {
    state.routeTo = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
